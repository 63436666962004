import React, { useState } from 'react';
import withTranslation from './hoc/withTranslation'; // Import the HOC

function RightColumn({ t }) {
  const workExperiences = t('workExperience', { returnObjects: true }) || [];
  const personalProject = t('personalProject', { returnObjects: true }) || [];
  const arrayEduc = t('education', { returnObjects: true }) || [];

  // Initialize state to control the visibility of the description list for each job
  const [showListExp, setshowListExp] = useState(
    workExperiences.map(() => false) // Create an array of false values for each job
  );
  const [showListPersonalProject, setShowListPersonalProject] = useState(
    personalProject.map(() => false)
  );

  // Toggle function to show/hide the description list for a specific job
  const toggleShow = (index, setterShowFunction) => {
    setterShowFunction((prevShowList) => {
      const newShowList = [...prevShowList];
      newShowList[index] = !newShowList[index];
      return newShowList;
    });
  };

  // Function to toggle all work experiences
  const toggleAllWorkExperiences = () => {
    const shouldExpandAll = showListExp.some((isShown) => !isShown); // If any item is collapsed, expand all
    setshowListExp(workExperiences.map(() => shouldExpandAll));
  };

  // Function to toggle all personal projects
  const toggleAllPersonalProjects = () => {
    const shouldExpandAll = showListPersonalProject.some((isShown) => !isShown); // If any item is collapsed, expand all
    setShowListPersonalProject(personalProject.map(() => shouldExpandAll));
  };

  return (
    <div className="w3-twothird">
      {/* Work Experience Section */}
      <div className="w3-container w3-card w3-white w3-margin-bottom">
        <h2 className="w3-text-grey w3-padding-16">
          <i className="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>
          {t('WORK_EXP')}
          <button
            className="expand-collapse-btn"
            onClick={toggleAllWorkExperiences}
          >
            {showListExp.every((isShown) => isShown) ? t('COLLAPSE_ALL') : t('EXPAND_ALL')}
          </button>
        </h2>
        {workExperiences.map((job, index) => (
          <div className="w3-container" key={index}>
            <h5 className="w3-opacity">
              <b>{job.title} / {job.company}</b>
            </h5>
            <h6 className="w3-text-teal" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>
                <i className="fa fa-calendar fa-fw w3-margin-right"></i>
                {job.startDate} - {job.endDate === "Current" ? (
                  <span className="w3-tag w3-teal w3-round">{t('current')}</span>
                ) : job.endDate}
              </span>

              {/* Chevron positioned at the far right */}
              <span onClick={() => toggleShow(index, setshowListExp)}
                style={{ cursor: 'pointer' }}
                aria-expanded={showListExp[index]}
                aria-label={showListExp[index] ? 'Hide job description' : 'Show job description'}>
                {showListExp[index] ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
              </span>
            </h6>
            
            {/* Conditionally render the description list */}
            {showListExp[index] && (
              <ul>
                {job.description.map((desc, descIndex) => (
                  <li key={descIndex}>{desc}</li>
                ))}
              </ul>
            )}

            <hr />
          </div>
        ))}
      </div>
      {/* Personal Project Section */}
      <div className="w3-container w3-card w3-white w3-margin-bottom">
      <h2 className="w3-text-grey w3-padding-16">
          <i className="fa fa-code fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>
          {t('MY_PROJECT')}
          <button
            className="expand-collapse-btn"
            onClick={toggleAllPersonalProjects}
          >
            {showListPersonalProject.every((isShown) => isShown) ? t('COLLAPSE_ALL') : t('EXPAND_ALL')}
          </button>
        </h2>
        {personalProject.map((project, index) => (
          <div className="w3-container" key={index}>
            <h5 className="w3-opacity">
              <b>{project.title} / {project.company}</b>
            </h5>
            <h6 className="w3-text-teal" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>
                <i className="fa fa-calendar fa-fw w3-margin-right"></i>
                {project.startDate} - {project.endDate === "Current" ? (
                  <span className="w3-tag w3-teal w3-round">{t('current')}</span>
                ) : project.endDate}
              </span>

              {/* Chevron positioned at the far right */}
              <span onClick={() => toggleShow(index, setShowListPersonalProject)} 
                style={{ cursor: 'pointer' }}
                aria-expanded={showListPersonalProject[index]}
                aria-label={showListPersonalProject[index] ? 'Hide job description' : 'Show job description'}>
                {showListPersonalProject[index] ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
              </span>
            </h6>
            
            {/* Conditionally render the description list */}
            {showListPersonalProject[index] && (
              <ul>
                {project.description.map((desc, descIndex) => (
                  <li key={descIndex}>{desc}</li>
                ))}
              </ul>
            )}

            <hr />
          </div>
        ))}
      </div>

      {/* Education Section */}
      <div className="w3-container w3-card w3-white">
      <h2 className="w3-text-grey w3-padding-16">
        <i className="fa fa-graduation-cap fa-fw w3-margin-right w3-xxlarge w3-text-teal"></i>
        {t('EDUCATION')} {/* Translated "Education" */}
      </h2>
      {arrayEduc.map((edu, index) => (
        <div className="w3-container" key={index}>
          <h5 className="w3-opacity">
            <b>{edu.institution}</b>
          </h5>
          <h6 className="w3-text-teal">
            <i className="fa fa-calendar fa-fw w3-margin-right"></i>{edu.startDate} {edu.endDate ? `- ${edu.endDate}` : ""}
          </h6>
          <p>{edu.degree}</p>
          {index !== arrayEduc.length - 1 && <hr />}
        </div>
      ))}
      </div>
    </div>
  );
}

export default withTranslation(RightColumn); // Wrap the component with the HOC
