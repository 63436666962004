import React, { useEffect  } from 'react';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import LanguageSwitcher from './LanguageSwitcher';
import './App.css';
import personData from './person.json';
import withTranslation from './hoc/withTranslation'; // Import the HOC
import DOMPurify from 'dompurify';

function App({ t }) {
  useEffect(() => {
    document.title = `${personData.firstname} ${personData.lastname} - ${t('ONLINE_CV')}`;
  }, [t]); // Include `t` as a dependency

  const cleanFooterHtml = DOMPurify.sanitize(t('FOOTER_MSG'), {
    ADD_ATTR: ['target'], // Allow 'target' attribute for <a> footer message
  });
  return (
    <>
      <div className="w3-content w3-margin-top" style={{ maxWidth: '1400px' }}>
        <LanguageSwitcher />
        <div className="w3-row-padding">
          <LeftColumn person={personData} />
          <RightColumn />
        </div>
      </div>
      {/* Footer Section */}
      <footer className="w3-center w3-padding-16" style={{ marginTop: '50px' }}>
        <p dangerouslySetInnerHTML={{ __html: cleanFooterHtml }} />
      </footer>
    </>
  );
}

export default withTranslation(App);
