import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Load translations via HTTP
  .use(LanguageDetector) // Detect user's language
  .use(initReactI18next) // Pass the i18n instance to react-i18next
  .init({
    fallbackLng: 'en-US', // Default language
    debug: false, // Enable for development; disable in production
    backend: {
      loadPath: '/locales/{{lng}}/translation.json' // Path to load translation files
    },
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

export default i18n;
