import React, { useState } from 'react';
import './App.css';
import withTranslation from './hoc/withTranslation'; // Import the HOC

function LeftColumn({ person, t }) {
  // State for toggling sections
  const [showSkills, setShowSkills] = useState(true);
  const [showQualities, setShowQualities] = useState(true);
  const [showLanguages, setShowLanguages] = useState(true);
  const [showInterests, setShowInterests] = useState(true);
  const [sortSkillsBy, setSortSkillsBy] = useState('name'); // Sorting for skills
  const [sortLangBy, setSortLangBy] = useState('name'); // Sorting for languages

  const skillsTable = person.skills[0];

  // Function to handle sorting by proficiency or name
  const sortItems = (items, key) => {
    return items.sort((a, b) => {
      if (key === 'proficiency') {
        return b.proficiency - a.proficiency;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
  };

  // State to track the expanded/collapsed state of each skill category
  const [showSkillSection, setShowSkillSection] = useState(
    new Array(Object.keys(skillsTable).length).fill(false)
  );

  // Toggle function for expanding/collapsing a skill category
  const toggleShow = (index, setShowSkillSection) => {
    setShowSkillSection(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
/**/

  return (
    <div className="w3-third">
      
      <div className="w3-white w3-text-grey w3-card-4">
        <div className="w3-display-container">
          <img src={person.avatar} style={{ width: "100%" }} alt="Avatar" />
          <div className="w3-display-bottomleft w3-container main-name-decorator">
            <h2 title={`${person.firstname} ${person.otherNames} ${person.lastname}`}>{person.firstname} {person.lastname}</h2>
          </div>
        </div>
        <div className="w3-container">
          <p>
            <a href="https://www.linkedin.com/in/willy-ung-304454ba" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-linkedin fa-fw w3-margin-right w3-large w3-text-teal"></i>{t('LINKED_IN')}
            </a>
          </p>
          <p>
            <i className="fa fa-home fa-fw w3-margin-right w3-large w3-text-teal"></i>{person.location}
          </p>
          
          <hr />

          {/* Skills Section */}
          <p className="w3-large">
            <b>
              <i className="fa fa-cogs fa-fw w3-margin-right w3-text-teal" title={t('SKILLS')}></i>
              <button
                className="button-3"
                onClick={() => setShowSkills(!showSkills)}
                title={`${showSkills ? t('HIDE') : t('SHOW')} ${t('SKILLS')}`}>
                {showSkills
                  ? <i className="fas fa-chevron-up"></i>
                  : <i className="fas fa-chevron-down"></i>
                }
              </button>
            </b>
            <select
              value={sortSkillsBy}
              onChange={(e) => setSortSkillsBy(e.target.value)}
              className="sort-select"
            >
              <option value="name">{t('ORDER_BY')} {t('NAME')}</option>
              <option value="proficiency">{t('ORDER_BY')} {t('PROFICIENCY')}</option>
            </select>
          </p>
          {showSkills && (
            <div>
              {/* Skill categories */}
              {Object.keys(skillsTable).map((category, index) => (
                <div
                  onClick={() => toggleShow(index, setShowSkillSection)}
                  key={index} className="w3-card-4 skill-card">
                  {/* Skill Category Header */}
                  <div className="skill-category-header">
                    <p>
                      <b>{t(category)}</b>
                    </p>
                    <span
                      style={{ cursor: 'pointer' }}
                      aria-expanded={showSkillSection[index]}
                      aria-label={showSkillSection[index] ? 'Hide skills' : 'Show skills'}>
                      {showSkillSection[index] ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
                    </span>
                  </div>
                  {/* Skill list in the category */}
                  {showSkillSection[index] && (
                    <div>
                      {sortItems([...skillsTable[category]], sortSkillsBy).map((skill, idx) => (
                        <div key={idx}>
                          <p>{skill.name}</p>
                          <div className="w3-light-grey w3-round-xlarge w3-small">
                            <div className="w3-container w3-center w3-round-xlarge w3-teal" style={{ width: `${skill.proficiency}%` }}>
                              {skill.proficiency}%
                            </div>
                          </div>
                        </div>
                      ))}
                      <br />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}


          {/* Qualities Section */}
          <p className="w3-large">
            <b>
              <i className="fa fa-thumbs-up fa-fw w3-margin-right w3-text-teal" title={t('QUALITIES')}></i>
              <button className="button-3" onClick={() => setShowQualities(!showQualities)}
                title={`${showQualities ? t('HIDE') : t('SHOW')} ${t('QUALITIES')}`}>
                {showQualities 
                  ? <i className="fas fa-chevron-up"></i> 
                  : <i className="fas fa-chevron-down"></i>}
              </button>
            </b>
          </p>
          {showQualities && (
            <ul>
              {t(`qualities`, { returnObjects: true }).map((quality, index) => (
                <li key={index}>
                  <b>{quality.name}:</b> {quality.description}
                </li>
              ))}
              <br />
            </ul>
          )}

          {/* Languages Section */}
          <p className="w3-large w3-text-theme">
            <b>
              <i className="fa fa-globe fa-fw w3-margin-right w3-text-teal" title={t('LANGUAGES')}></i>
              <button className="button-3" onClick={() => setShowLanguages(!showLanguages)}
                title={`${showLanguages ? t('HIDE') : t('SHOW')} ${t('LANGUAGES')}`}>
                {showLanguages 
                  ? <i className="fas fa-chevron-up"></i> 
                  : <i className="fas fa-chevron-down"></i>}
              </button>
            </b>
            <select
              value={sortLangBy}
              onChange={(e) => setSortLangBy(e.target.value)}
              className="sort-select"
            >
              <option value="name">{t('ORDER_BY')} {t('NAME')}</option>
              <option value="proficiency">{t('ORDER_BY')} {t('PROFICIENCY')}</option>
            </select>
          </p>
          {showLanguages && (
            <div>
              {sortItems([...person.languages], sortLangBy).map((language, index) => (
                <div key={index}>
                  <p>{t(language.name)}</p>
                  <div className="w3-light-grey w3-round-xlarge">
                    <div className="w3-round-xlarge w3-teal" style={{ height: "24px", width: `${language.proficiency}%` }}></div>
                  </div>
                </div>
              ))}
              <br />
            </div>
          )}
          
          {/* Interests Section */}
          <p className="w3-large w3-text-theme">
            <b>
              <i className="fa fa-heart fa-fw w3-margin-right w3-text-teal" title={t('INTERESTS')}></i>
              <button className="button-3" onClick={() => setShowInterests(!showInterests)}
                title={`${showInterests ? t('HIDE') : t('SHOW')} ${t('INTERESTS')}`}>
                {showInterests 
                  ? <i className="fas fa-chevron-up"></i> 
                  : <i className="fas fa-chevron-down"></i>}
              </button>
            </b>
          </p>
          {showInterests && (
            <ul>
              {t(`interests`, { returnObjects: true }).map((interest, index) => (
                <li key={index}>{interest}</li>
              ))}
            </ul>
          )}

        </div>
      </div>
      <br />
    </div>
  );
}

export default withTranslation(LeftColumn); // Wrap the component with the HOC