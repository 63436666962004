import React from 'react';
import { useTranslation } from 'react-i18next';
import './i18n';
import withTranslation from './hoc/withTranslation'; // Import the HOC

function LanguageSwitcher({ t }) {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flag-container">
      <span onClick={() => changeLanguage('en-US')} className="flagLang" title={t('LANG_EN') + ' (US)'}>🇺🇸</span>
      <span onClick={() => changeLanguage('fr-FR')} className="flagLang" title={t('LANG_FR') + ' (FR)'}>🇫🇷</span>
    </div>
  );
}

export default withTranslation(LanguageSwitcher); // Wrap the component with the HOC